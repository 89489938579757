import React from "react"

// images
import facebook from "../../images/homepage/facebook.png"
import youtube from "../../images/homepage/youtube.png"

// components
import ExternalLink from "../externalLink/externalLink"

const LeftContainer = () => {
  return (
    <div className="left_container">
      <div className="left_inner_containers">
        <h2 className="homepage_headlines">About the program</h2>
        <p>
          Amarin is encouraging people who
          have already had a heart attack or stroke to learn about their risk–and 
          talk with their healthcare provider about the right treatment for
          their individual condition.
        </p>
        <h2 className="homepage_headlines">Why?</h2>
        <p>
          Many patients who have already had a heart attack are up to 50% more
          likely to have another cardiovascular (CV) event (such as heart attack or
          stroke) or heart procedure within only 1 year.
        </p>
        <p>
          Whether you’re someone who has experienced a CV event
          personally, you know a friend or loved one who's been affected, or you’re
          a caregiver or healthcare professional, we are asking everyone to
          act now and use <strong>#RaiseARedFlag</strong> to bring attention to this important
          matter. Don’t wait for another CV event. Act now for yourself and for your loved ones, and talk with your healthcare provider about your individual risk. <strong>#RaiseARedFlag</strong>
        </p>
        <h2 className="homepage_headlines">How?</h2>
        <p>
          Post to your profile and use the hashtag <strong>#RaiseARedFlag</strong> to help spread
          the word! Include the link{" "}
          <a link="#" className="rarf_link" target="_blank">RaiseARedFlag.com</a>{" "}
          to share more helpful information about heart disease, to find a
          healthcare provider, and to schedule an appointment either in-person
          or via a telehealth visit.
        </p>
        <h2 className="homepage_headlines">
          Visit us on Facebook and YouTube
        </h2>
        <div className="social_icons_container">
          <a href="https://www.facebook.com/VASCEPA-icosapent-ethyl-114053176979445" target="_blank" id="FACEBOOK_LINK">
          <img src={facebook} alt="facebook logo" />
          </a>
          <a href="https://www.youtube.com/channel/UCkPC2PsHuZTl3pFnmPyoFYg" target="_blank" id="YOUTUBE_LINK">
          <img src={youtube} alt="youtube logo" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default LeftContainer
