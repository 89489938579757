import React from "react"

// components
import ExternalLink from "../externalLink/externalLink"

// images
import RARF_Twibbon from "../../images/homepage/RARF_Twibbon.png"
import rarf from "../../images/homepage/Red_Flag_Logo_4c_TM.png"
import profile_download from "../../images/homepage/profile_download.png"
import Red_Flag_Logo from "../../images/homepage/raise_flag.png"

const RightContainer = () => {
  return (
    <div className="right_container">
      {/* first right container */}
      <div className="right_inner_containers">
        <div>
          <h2 className="homepage_headlines">
            Getting started on social media
          </h2>
          <p>
            Join the movement on social media. Use the following as a starting point for
            your own social media post to start creating awareness:
          </p>
        </div>

        <div className="right_inner_flex_container">
          <div className="left_inner">
            <h3>Example social media post</h3>
            <p className="white_inner_p">
              I am proud to <strong>#RaiseARedFlag</strong> to bring awareness to such an
              important issue. Join the movement and <strong>#actnow</strong>! For more
              information, visit{" "}
              <a link="#" className="rarf_link" target="_blank">www.RaiseARedFlag.com</a>
            </p>
          </div>
          <div className="right_inner">
            <h3>URLs, hashtags, and accounts to tag</h3>
            <div className="white_inner_p mobile_h_70">
              <p>RaiseARedFlag.com</p>
              <p>#RaiseARedFlag</p>
              <p>#actnow</p>
            </div>
          </div>
        </div>
      </div>

      {/* second right container */}
      <div className="right_inner_containers">
        <h2 className="homepage_headlines">Graphics to download</h2>
        <p>
          <strong>
            Download graphics to include with your social media post:
          </strong>
        </p>

        <div className="right_inner_flex_container gap2x">
          <div className="left_inner text-center">
            <img src={RARF_Twibbon} alt="profile picture" style={{maxHeight: "120px"}} />
            <div className="div_btn">
              <a id="TWIBBON_A_PROFILE_PICTURE_FRAME" className="img_download_btn" href={profile_download} target="_blank" download={profile_download}>Download</a>
            </div>
            <div className="text-container">
              <p>
                <strong>Twibbon: A profile picture frame</strong>
              </p>
              <p className="p-light">
                <em>
                  Click download above; log in to your Twitter and Facebook accounts and
                  select the <em>'add to Twitter'</em> or <em>'add to Facebook'</em> buttons for
                  automatic profile upload.
                </em>
              </p>
            </div>
          </div>
          <div className="right_inner text-center">
            <img src={rarf} alt="Raise a Red Flag logo" style={{maxHeight: "120px"}} />
            <div className="div_btn">
              <a id="RAISE_A_RED_FLAG_DOWNLOAD" className="img_download_btn" href={rarf} download={rarf}>Download</a>
            </div>
            <div className="text-container">
              <p>
                <strong>#RaiseARedFlag graphic</strong>
              </p>
              <p className="p-light">
                <em>Include with your social media post.</em>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* thrid right container */}
      <div className="right_inner_containers">
        <h2 className="homepage_headlines">
        Why we chose a Red Flag. To raise a red flag means:
        </h2>
        <div className="right_inner_flex_container gap2x">
          <div className="left_inner">
            <div className="text-container">
              <p>
                <strong>General</strong>
              </p>
              <p className="m-b-0">
                A red flag is a well-known signal indicating potential or
                imminent danger or trouble.
              </p>
            </div>
          </div>
          <div className="right_inner m-t-0">
            <div className="text-container">
              <p className="">
                <strong>Heart health</strong>
              </p>
              <p>
                For patients, a red flag means to raise awareness that once a
                patient&#x2014;or a loved one&#x2014;had a heart attack or stroke, their
                risk of another cardiovascular event is substantially higher.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RightContainer
