import React from "react"
import $ from "jquery"



const ExternalLink = ({ link, label, styleName }) => {
  const handleOpen = e => {
    e.preventDefault();
    if(styleName === "rarf_link"){
      $("#RARF_modal").modal("show")
      let continueBtn = document.querySelector('#RARF_modal #RARFModalLink');
      continueBtn.href = link;
    }
    else{
      $("#leavingPageModal").modal("show")
      let continueBtn = document.querySelector('#leavingPageModal #leavingModalLink');
      continueBtn.href = link;
    }
    
  }

  return (
    <a className={styleName} href="" onClick={handleOpen} data-link={link}>
      {label}
    </a>
  )
}
export default ExternalLink