import React from "react"

// components
import LeftContainer from './leftcontainer'
import RightContainer from "./rightcontainer"

// images
import rarf from '../../images/homepage/Red_Flag_Logo_4c_TM.png'

// styles
import "./homepage.scss"
import Chatbox from "../chatbox/chatbox"

const HomePage = () => {
  return (
    <div>
      <div className="headline_flex_container">
        <div className="header_img_container">
          <img src={rarf} alt="Raise a Red Flag logo"/>
        </div>
        <div className="main_headline">
          
          <h1>Join us in fighting heart disease</h1>
        </div>

      </div>
      <div className="homepage rrf">
        {/* left container */}
  
        <LeftContainer/>
  
        {/* left container ends */}
  
        {/* right container */}
  
        <RightContainer/>

        {/* right container ends */}
      </div>
    </div>
  )
}

export default HomePage
